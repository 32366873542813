<template>
    <div>
        <div class="table_content">
            <!-- 表格内容 -->
            <commonTitle></commonTitle>
            <div class="search">
                <el-form ref="form" :model="form" label-width="80px" :inline="true">
                    <el-form-item label="单元名称:">
                        <el-input v-model="form.name" placeholder="请输入单元名称" size="mini"></el-input>
                    </el-form-item>
                    <button @click.prevent="common()" style="cursor: pointer">搜索</button>
                    <button @click.prevent="add" style="cursor: pointer" v-show="isShow">新建</button>
                </el-form>
            </div>
            <el-card>
                <el-table
                        :data="tableData"
                        v-loading="loading"
                        element-loading-text="拼命加载中"
                        style="width:100%"
                        
                        :default-sort="{prop: 'settingTime', order: 'descending'}"
                        :stripe="true"
                >
                    <el-table-column
                            prop="villageName"
                            label="小区名称"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            prop="towerNum"
                            label="楼幢号"
                            width="200">
                    </el-table-column>
                    <el-table-column
                            label="单元名称"
                            width="200"
                            fixed="left"
                    >


                        <template slot-scope="scope">
                            <el-link type="primary" :underline="false" @click="lookHouse( scope.row)">
                                {{scope.row.unitName}}
                            </el-link>
                        </template>

                    </el-table-column>
                    <el-table-column
                            fixed="right"
                            label="操作"
                            width="300">
                        <template slot-scope="scope">

                            <el-button @click="upUnit(scope.row)" type="text" size="small"
                                       :disabled="scope.row.actResult!=4">修改
                            </el-button>
                            <el-button
                                    @click.native.prevent="deleteRow(scope.$index, scope.row,tableData)"
                                    type="text"
                                    size="small" :disabled="scope.row.actResult!=4">
                                删除
                            </el-button>
                            <el-button
                                    size="mini"
                                    type="text"
                                    :disabled="false"
                                    @click="uploadfile(scope.row)">上传
                            </el-button>
                            <el-button
                                    size="mini"
                                    type="text"
                                    :disabled="false"
                                    @click="downloadfile(scope.row)">下载
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-row>
                    <el-pagination
                            background
                            style="margin-top: 50px"
                            layout="prev, pager, next"
                            :page-size="15"
                            @current-change="handleCurrentChange"
                            :total="totalNum">
                    </el-pagination>
                    <el-button @click="back" class="btn" size="small">上一步</el-button>
                </el-row>
            </el-card>
        </div>
        <!--上传弹框-->
        <el-dialog
                title="设计图上传"
                :visible.sync="dialogVisible"
                width="30%"
                style="text-align: center">
            <el-upload
                    accept="image/png,image/jpg"
                    class="upload-demo"
                    drag
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :before-remove="beforeRemove"
                    :file-list="fileList"
                    :action="designUrl"
                    :before-upload="designUpload"
                    :on-success="designFileSuccess"
                    :limit="6"
                    :auto-upload="true"
                    :on-exceed="handleExceed"
                    multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传png/jpg 格式文件
                </div>
            </el-upload>
            <span slot="footer" class="dialog-footer">
               <el-button @click="submitHistoryUpload" type="primary" size="small">保存</el-button>
               <el-button @click="dialogVisible = false" size="small">关闭</el-button>
             </span>
        </el-dialog>
    </div>
</template>

<script>
    import commonTitle from "./commonTitle";

    export default {
        name: "deveUnit",
        components: {
            commonTitle
        },
        data() {
            return {
                currentTime: new Date().getFullYear() + '年' + (new Date().getMonth() + 1) + '月' + new Date().getDate() + '日 ' +
                    new Date().getHours() + ":" + new Date().getMinutes(), // 获取当前时间
                tableData: [],//表格数据集
                totalNum: 15,
                select0: '',//搜索值
                unitId:'',
                villageId:'',
                towerId:'',
                unitName:'',
                dialogVisible: false,//文件上传弹出层
                designFileId:'',
                fileList:[],
                designList:[],
                designUrl: '',
                form: {
                    name: "",
                    region: '',
                },
                loading: false,
                url: this.$Config.base_server,
                isShow: '',
                data: {
                    access_token: localStorage.getItem("token"),
                    limit: 15,
                    searchKey: 'towerId',
                    searchValue: JSON.parse(localStorage.getItem("towerData")).towerId
                }
            };
        }, mounted() {
            this.common();
            let actResult = JSON.parse(localStorage.getItem("villageData")).actResult;
            if (actResult == 4) {
                //小区未提交状态
                this.isShow = true;
            } else {
                //小区其他状态
                this.isShow = false;
            }
        }, methods: {
            common(currentPage = 0) {
                //初始化单元数据
                const that = this;
                that.loading = true;
                that.data.page = currentPage;
                that.data.unitName = that.form.name; //搜索框中的值
                $.ajax({
                    url: this.url + "/api-public/opeunit/list",
                    data: that.data,
                    contentType: "application/json",
                    type: "get",
                    success: function (res) {
                        if (res.code == 0) {
                            that.loading = false;
                            that.totalNum = res.count;
                            that.tableData = res.data;
                        }
                    }
                })
            }, lookHouse(row) {
                //查看户室信息
                this.$router.push({name: 'deveHouse'});
                //存储单元信息
                localStorage.setItem("unitData", JSON.stringify(row));
            }, changePwd() {
                //修改密码
                window.location.href = "../first_tab/changepwd.html";
            },
            back() {
                //返回按钮
              this.$router.push('/deveTower');
            },
            handleSizeChange(val) {
                console.log('每页${val}条');
                this.pagesize = val;
            },
            handleCurrentChange(val) {
                console.log('当前页' + val);
                this.currpage = val;
                this.common(val);
            }, upUnit(row) {
                //修改按钮
                this.$router.push({name: 'upUnit'}); //跳转修改单元页面
                //存储选中的单元信息
                localStorage.setItem("unitData", JSON.stringify(row));
            }, deleteRow(index, rows, tableDate) {
                let _this = this;
                let _rows = rows;
                let _index = index;
                //删除相应单元
                this.$confirm('此操作将永久删除该单元信息, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(function () {
                    $.ajax({
                        url: _this.url + "/api-public/opeunit/delete/" + _rows.unitId + "?access_token=" + localStorage.getItem("token"),
                        type: 'delete',
                        success: function (data) {
                            if (data.resp_code == 0) {
                                var that = _this;
                                tableDate.splice(index, 1);
                                //成功
                                that.$message({
                                    type: 'success',
                                    message: '删除成功!'
                                });
                            }
                        }
                    });
                }).catch(function () {
                });
            }, add() {
                //新增按钮
                this.$router.push({name: 'addUnit'});
            },
            designUpload(){
                return new Promise((resolve, reject) => {
                    this.$nextTick(() => {
                        this.designUrl = this.url + "/api-file/files/anon?access_token=" + localStorage.getItem("token"),
                            resolve()
                    })
                })
            }, designFileSuccess(response, file, fileList){
                this.designList.push(response.id);
            }, uploadfile(row){
                const  that=this;
                that.row=row;
                that.dialogVisible = true;
                that.villageId=that.row.villageId;
                that.unitId=that.row.unitId;
                that.towerId=that.row.towerId;
                that.unitName=that.row.unitName;

            }, downloadfile(row){
                if (row.unitDesignFile==null){
                    this.$message.warning('未上传设计图，请先上传设计图！');
                }else {
                    location.href = this.url + '/api-file/files/downZipByfileId?fileid=' + row.unitDesignFile + '&access_token=' + localStorage.getItem("token");
                }
            }, handlePreview(file) {
                console.log(file);
            }, handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 6 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            }, beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${ file.name }？`);
            }, handleRemove(file, fileList) {
                this.designList=this.designList.filter(e=>e!=file.response.id)
                console.log(this.designList)
                console.log(file, fileList);
            }, submitHistoryUpload() {
                let _this = this;
                var designList= _this.designList;
                var unitDesignFile = designList.join("-");
                const data = {
                    unitDesignFile: unitDesignFile,
                    unitId: _this.unitId,
                    villageId: _this.villageId,
                    towerId: _this.towerId,
                    unitName: _this.unitName
                }
                if (designList == null || designList==""){
                    this.$message.warning('请上传设计图后在保存');
                }else{
                    $.ajax({
                        url: _this.url + "/api-public/opeunit/update?access_token=" + localStorage.getItem("token"),
                        type: 'POST',
                        data: JSON.stringify(data),
                        contentType: 'application/json',
                        success: function (data) {
                            if (data.resp_code == 0) {
                                _this.$message({
                                    type: 'success',
                                    message: '设计图已保存!'
                                });
                                _this.dialogVisible = false
                            }else {
                                _this.$message({
                                    type: 'warning',
                                    message: '设计图保存失败'
                                })
                                _this.dialogVisible = false
                            }
                        }
                    });
                }
                //确定上传按钮
                console.log(this.designList)
            },
        }
    }
</script>

<style scoped>
    .btn {
        float: right;
        transform: translateY(-34px);
    }
</style>